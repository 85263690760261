a {
	transition-duration: 300ms;
}
img {
	max-width: 100%;
	height: auto;
}

.nav-pills .nav-link {
	border-radius: 0;
}

$navbar-icon-width: 70px;
.main-header {
	padding: 0;
	height: 55px;
	.navbar-nav {
		width: $navbar-icon-width;
		height: 100%;
		.nav-item {
			width: 100%;
			height: 100%;
			.nav-link {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				font-size: 24px;
				padding: 0;
				img {
					width: 24px;
				}
				&:hover, &.active {
					background-color: $orange;
					color: #fff;
				}
				&.disable {
					background: $gray-700;
					color: $gray-500;
					&:hover {
						background: $gray-700;
						color: $gray-500;
					}
				}
			}
		}
		&:first-child {
			border-right: 1px solid $gray-300;
		}
		&:last-child {
			border-left: 1px solid $gray-300;
		}
	}
	.navbar-prev-next {
		display: flex;
		width: calc(100% - #{$navbar-icon-width} * 2);
		.nav-item {
			width: 50%;
			&:first-child {
				border-right: 1px solid $gray-300;
			}
			.nav-link {
				background-color: $gray;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

@include media-breakpoint-up(lg) {
	.content-wrapper {
		.content-header, .content {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

.content-header {
	margin-top: 20px;
	h2 {
		font-size: 24px;
		font-weight: bold;
		margin-top: 20px;
	}
}

/*--------------------
サイドバー
---------------------*/
@include media-breakpoint-down(md) {
	body.sidebar-open {
		.main-sidebar {
			transition: margin-left .3s ease-in-out,width .3s ease-in-out;
		}
	}
}

.main-sidebar {
	transition: none;
	.sidebar-top {
		padding: 20px 15px 15px;
		background-color: #fff;
		border-bottom: 1px solid $border-color;
		.brand-link {
			width: 100%;
			img {
				width: 100%;
				max-width: 300px;
				margin-bottom: 15px;
			}
		}
		.info {
			font-size: 12px;
		}
	}
	.nav-sidebar {
		.nav-item {
			&:first-child, &:last-child {
				border-top: 1px solid $border-color;
			}
			&:last-child {
				margin-top: .5rem;
			}
			.nav-link {
				padding: 20px 20px;
				border-bottom: 1px solid $border-color;
				margin-bottom: 0;
				background-color: #fff;
				position: relative;
				i {
					margin-right: 10px;
					font-size: 1.2em;
				}
				.cnt {
					background-color: #d00101;
					color: #fff;
					border-radius: 50%;
					position: absolute;
					right: 15px;
					top: 20px;
					min-height: 20px;
					min-width: 20px;
					text-align: center;
					font-size: 14px;
				}
			}
		}
	}
	.wrap-card-list {
		margin: 8px 0;
		.card-course {
			margin-bottom: 8px;
			&:first-child {
				.card-header {
					border-top: 1px solid $gray-400;
				}
			}
			&.collapsed-card {
				margin: 0;
			}
			.card-header {
				margin-bottom: 0;
				min-height: 60px;
				height: auto;
				padding-top: 10px;
				padding-bottom: 10px;
				border-bottom: 1px solid $gray-400;
				.card-title {
					font-size: 14px;
				}
			}
			.card-body {
				ul {
					li {
						padding-left: 30px;
						margin-bottom: 0;
						font-size: 14px;
						height: 70px;
						border-bottom: 1px solid $gray-400;
						background-color: #fff;
						&.active {
							background-color: $orange-light;
							&:before {
								top: 26px;
								left: 12px;
							}
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.sidebar-top {
			.hide-menu {
				display: none;
			}
		}
	}
	@include media-breakpoint-down(md) {
		// transition: margin-left .3s ease-in-out,width .3s ease-in-out;
		width: 100%;
		.sidebar-top {
			position: relative;
			.hide-menu {
				position: absolute;
				width: 55px;
				height: 55px;
				display: block;
				top: 0;
				right: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $gray-500;
				font-size: 24px;
				z-index: 1;
			}
		}
	}
}

/*--------------------
ホーム
---------------------*/
.next-course {
	background-color: $orange-light;
	border-radius: 30px;
	margin-bottom: 40px;
	// padding: 10px 0;
	.title {
		background-color: $orange;
		border-radius: 30px;
		color: #fff;
		padding: 10px 40px 10px 25px;
		display: inline-block;
		margin-right: 20px;
		position: relative;
		&:after {
			content: "\f105";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			position: absolute;
			right: 15px;
		}
	}
}

.card-course {
	margin-bottom: 2px;
	box-shadow: none;
	.card-header {
		background-color: #DFE0E2;
		border-bottom: none;
		margin-bottom: 2px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px 0 20px;
		height: 70px;
		border-radius: 0;
		.card-title {
			font-size: 18px;
			font-weight: bold;
			line-height: 1.5;
		}
		.btn {
			font-size: 22px;
		}
		&:after {
			content: none;
		}
	}
	.card-body {
		padding: 0;
		ul {
			list-style-type: none;
			padding: 0;
			margin-bottom: 0;
			li {
				background-color: $gray;
				margin: 0 0 2px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 10px 10px 40px;
				height: 60px;
				line-height: 1.5;
				.btn {
					flex-shrink: 0;
					margin-left: 10px;
				}
				&.active {
					background-color: $orange-light;
					position: relative;
					&:before {
						content: url(../img/base/i_pointer.png);
						position: absolute;
						left: 18px;
						top: 20px;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.card-header {
			height: 55px;
			.card-title {
				font-size: 14px;
			}
		}
		.card-body {
			ul {
				li {
					height: 70px;
					.btn {
						font-size: 14px;
						width: 90px;
						padding-left: 5px;
						padding-right: 15px;
						&:after {
							right: 7px;
							top: 9px;
						}
					}
				}
			}
		}
	}
}

.btn-orange {
	background-color: $orange;
	color: #fff;
	border-radius: 5px;
	font-weight: bold;
	border: none;
	position: relative;
	padding: 8px 30px 8px 20px;
	&:after {
		content: "\f105";
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		position: absolute;
		right: 10px;
		top: 8px;
	}
	&.btn-rounded {
		border-radius: 25px;
		padding: 12px 60px 12px 50px;
		&:after {
			top: 12px;
			right: 12px;
		}
	}
	&:hover {
		color: #fff;
		opacity: .7;
	}
}

.btn-back {
	background-color: $gray;
	color: $gray-600;
	border-radius: 5px;
	border: none;
	position: relative;
	padding: 8px 20px 8px 30px;
	border: 1px solid $gray-500;
	&:after {
		content: "\f053";
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		position: absolute;
		left: 10px;
		top: 8px;
	}
	&.btn-rounded {
		border-radius: 25px;
		padding: 12px 50px 12px 60px;
		&:after {
			top: 12px;
			left: 12px;
		}
	}
}
.btn-back-top {
	@extend .btn-back;
	padding: 8px 40px 8px 40px !important;
	color: $gray-700;
	border-color: $gray-700;
	&:after {
		content: none;
	}
}

/*--------------------
記事
---------------------*/
.wrap-next-prev {
	display: flex;
	.prev, .next {
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-weight: 500;
		background-color: $gray;
		height: 48px;
		color: $black;
		&:hover {
			background-color: $orange;
			color: #fff;
		}
		&.disable {
			background: $gray-700;
			color: $gray-500;
			&:hover {
				background: $gray-700;
				color: $gray-500;
			}
		}
	}
	.prev {
		i {
			margin-right: 10px;
		}
	}
	.next {
		i {
			margin-left: 10px;
		}
	}
	@include media-breakpoint-down(md) {
		display: none;
	}
}
.article {
	line-height: 2.2;
	.article-meta {
		margin-bottom: 15px;
		h2 {
			font-size: 24px;
			font-weight: bold;
			line-height: 1.5;
		}
		.date {
			color: $gray-500;
			i {
				margin-right: 10px;
			}
		}
	}
	p {
		margin-bottom: 2em;
	}
	img {
		margin: 20px 0;
		max-width: 100%;
	}
	@include media-breakpoint-down(md) {
		.article-meta {
			border-top: 1px solid $gray-500;
			border-bottom: 1px solid $gray-500;
			padding: 20px 0 10px;
			h2 {
				font-size: 16px;
			}
		}
	}
}

.card-comment {
	.avatar {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		margin-right: 5px;
	}
	>.card-body {
		h4 {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 20px;
		}
	}
	.card-comment-form {
		background-color: $orange-light;
		margin-bottom: 40px;
		.avatar {
			margin-bottom: 5px;
		}
		h5 {
			font-size: 16px;
			font-weight: bold;
			display: inline;
		}
		textarea {
			border-radius: 5px 5px 0 0;
		}
		.uploads {
			background-color: #f4f6fa;
			border-radius: 0 0 5px 5px;
			border: 1px solid #ced4da;
			border-top: none;
			padding: 15px 20px 0;
			.file_comment {
				display:none;
			}
			.file_mask_comment span{
				display:block;
				background-image:url(../img/base/i_plus.png);
				background-size: 40px;
				background-position:left top;
				height:40px;
				width:40px;
				cursor:pointer;
			}
			.item {
				display: inline-block;
				position: relative;
				>img {
					width: 40px;
				}
				.btn_close {
					position: absolute;
					top: -10px;
					right: -5px;
					img {
						width: 20px;
					}
				}
				&:not(:last-of-type) {
					margin-right: 20px;
				}
			}
		}
		.btn {
			margin-top: 20px;
			float: right;
			background-color: $orange;
			color: #fff;
			border-radius: 25px;
			font-weight: bold;
			border: none;
			position: relative;
			padding: 12px 20px 12px 20px;
		}
	}
	.count {
		font-weight: bold;
		margin-bottom: 15px;
		span {
			margin-right: 5px;
		}
	}
	.comment-list {
		>.unit {
			padding: 0;
			list-style-type: none;
			.card {
				margin-bottom: 20px;
				.card-header {
					display: flex;
					justify-content: space-between;
					border-bottom: none;
					padding-top: 15px;
					padding-bottom: 0;
					&:after {
						content: none;
					}
					.meta {
						display: flex;
						justify-content: flex-start;
						.avatar {
							position: relative;
							top: -2px;
						}
						.name {
							font-weight: bold;
						}
						.job {
							background-color: $black;
							color: #fff;
							font-size: 13px;
							padding: 2px 10px 1px;
							margin-left: 10px;
							height: 23px;
						}
						.date {
							color: $gray-500;
							margin-left: 15px;
						}
					}
				}
				.card-body {
					padding-top: 10px;
					.to {
						font-weight: bold;
						margin-bottom: 6px;
					}

		.uploads {
			margin-top: 15px;
			.file_comment {
				display:none;
			}
			.file_mask_comment span{
				display:block;
				background-image:url(../img/base/i_plus.png);
				background-size: 40px;
				background-position:left top;
				height:40px;
				width:40px;
				cursor:pointer;
			}
			.item {
				display: inline-block;
				position: relative;
				>img {
					width: 40px;
				}
				.btn_close {
					position: absolute;
					top: -10px;
					right: -5px;
					img {
						width: 20px;
					}
				}
				&:not(:last-of-type) {
					margin-right: 20px;
				}
			}
		}



				}
				.card-footer {
					border-top: none;
					padding-top: 0;
					background-color: transparent;
					.action {
						display: flex;
						justify-content: flex-end;
					}
				}
				.action {
					>a {
						color: $gray-800;
						i {
							margin-right: 1px;
						}
						&.reply {
							color: #336699;
						}
						&:not(:last-child) {
							margin-right: 15px;
						}
					}
				}
			}
		}
	}
	@include media-breakpoint-up(md) {
		padding: 30px;
		.card-comment-form {
			.uploads {
				.file_mask_comment {
					margin-right: 20px;
				}
				.list_item {
					display: inline-block;
					position: relative;
					top: -23px;
				}
			}
		}
		.comment-list {
			>.unit {
				.card {
					background-color: $gray;
					position: relative;
					&.child {
						background-color: $orange-light;
						margin-left: 50px;
					}
					&:not(:last-child) {
						&:before {
							content: '';
							position: absolute;
							display: block;
							height: 20px;
							width: 10px;
							bottom: -20px;
							left: 80px;
							background-color: #ccc;
						}
						&.child {
							&:before {
								left: 30px;
							}
						}
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		box-shadow: none;
		.card-comment-form {
			.uploads {
				padding-bottom: 15px;
				.file_mask_comment {
					margin-bottom: 0;
				}
				.list_item {
					.item {
						margin-top: 15px;
						&:not(:last-of-type) {
							margin-right: 10px;
						}
					}
				}
			}
		}
		>.card-body {
			padding: 0;
		}
		.comment-list {
			>.unit {
				border-bottom: 1px solid $gray-300;
				.card {
					box-shadow: none;
					padding-bottom: 10px;
					margin-bottom: 0;
					background-color: transparent;
					.card-header, .card-body, .card-footer {
						padding-left: 0;
						padding-right: 0;
					}
					.card-body {
						padding-bottom: 10px;
						margin-left: 35px;
						.uploads {
							padding-bottom: 15px;
							.list_item {
								.item {
									margin-top: 15px;
									&:not(:last-of-type) {
										margin-right: 10px;
									}
								}
							}
						}
					}
					&.child {
						.card-header {
							position: relative;
						}
					}
					&:not(:last-child) {
						&:before {
							content: '';
							position: absolute;
							display: block;
							height: 100%;
							width: 4px;
							top: 30px;
							left: 13px;
							background-color: #ccc;
						}
					}
				}
			}
		}
	}
}

.modal-comment {
	.modal-body {
		padding: 0;
		.card-comment {
			padding: 0 !important;
			>.card-body {
				padding: 0;
				>.card-comment-form {
					margin-bottom: 0;
				}
			}
		}
		
	}
}

/*--------------------
お知らせ
---------------------*/
.border-bottom-orange {
	display: inline-block;
	border-bottom: 1px solid $orange;
}

.news-list {
	margin-bottom: 50px;
	.card {
		color: $black;
		margin-bottom: 20px;
		.card-body {
			position: relative;
			h3 {
				font-weight: bold;
			}
			p {
				margin-bottom: 10px;
			}
			.date {
				color: $gray-500;
				i {
					margin-right: 10px;
				}
			}
		}
		&:hover {
			background-color: $orange-light;
		}
	}
	@include media-breakpoint-up(md) {
		.card {
			.card-body {
				padding: 40px 100px 40px 40px;
				h3 {
					font-size: 24px;

				}
				&:after {
					content: "\f054";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					color: $gray-500;
					position: absolute;
					right: 30px;
					top: 43%;
					font-size: 22px;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.card {
			.card-body {
				h3 {
					font-size: 20px;
				}
				p {
					 display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden; 
				}
			}
		}
	}
}

.pagination {
	justify-content: center;
	align-items: center;
	.page-item {
		.page-link {
			color: $black;
			border-color: $orange;
			border-radius: 50%;
			margin-left: 10px;
			text-align: center;
			width: 38px;
			height: 38px;
			&.prev, &.next {
				border-radius: 25px;
				width: auto;
				padding-left: 15px;
				padding-right: 15px;
			}
			&:hover, &.active {
				background-color: $orange;
				color: #fff;
			}
		}
	}
	&.sp-prev-next {
		display: none;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 5px;
		.page-item {
			.page-link {
				font-size: 18px;
				width: 42px;
				height: 42px;
				padding: 9px 0;
			}
		}
		&.sp-prev-next {
			display: block;
			overflow-y: hidden;
			margin: 10px 0 15px;
			.page-item {
				.page-link {
					&.prev, &.next {
						border-radius: 10px;
						width: auto;
					}
					&.prev {
						float: left;
					}
					&.next {
						float: right;
					}
				}
			}
		}
	}
}


/*--------------------
お問い合わせ
---------------------*/
.info-box {
	min-height: unset;
	padding: 1rem 1.5rem;
	margin-bottom: 1.5rem;
	.info-box-text {
		font-weight: bold;
		white-space: normal;
		line-height: 1.8;
	}
}

.card-inquiry {
	margin-bottom: 30px;
	.card-body {
		.form-group {
			.inner {
				border-bottom: 1px solid $gray-300;
				padding-bottom: 15px;
				.col-form-label {
					.required {
						color: $danger;
						margin-left: 10px;
					}
				}
			}
			.message {
				padding: 5px 20px 0;
				padding-top: 5px;
				font-size: 14px;
				display: none;
			}
			&.has-count {
				.message {
					display: block;
					overflow: hidden;
					.count {
						float: right;
					}
				}
			}
			&.has-error {
				.inner {
					border-color: $danger;
				}
				.message {
					display: block;
					color: $danger;
				}
			}
			textarea {
				width: 100%;
				min-height: 120px;
				line-height: 28px;
				overflow-y: hidden;
			}
		}
	}
	@include media-breakpoint-up(md) {
		.card-body {
			padding: 30px;
			.form-group {
				.inner {
					.col-form-label {
						padding-left: 20px;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
	}
}

.form-inquiry {
	.wrap-btns {
		text-align: center;
	}
	@include media-breakpoint-up(md) {
		.wrap-btns {
			.btn {
				&:not(:last-child) {
					margin-right: 15px;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.wrap-btns {
			.btn {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}
		}
		
	}
}

/*--------------------
退会について
---------------------*/
.card-unsubscribe {
	.card-body {
		line-height: 1.8;
		ol {
			li {
				margin-bottom: 1rem;
			}
		}
		h3 {
			color: $orange;
			font-weight: bold;
			font-size: 20px;
			margin-bottom: 5px;
		}
		h4 {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 20px;
			line-height: 1.5;
		}
		hr {
			margin: 1.5rem 0;
			border-color: $gray-400;
		}
		.fa-external-link-alt {
			font-size: .9rem;
		}
	}
	@include media-breakpoint-up(md) {
		padding: 15px 15px 5px;
		.card-body {
			p {
				font-size: 18px;
			}
			.pt-md-only {
				padding-top: 70px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding-top: 10px;
		.card-body {
			hr {
				margin: 2.5rem 0;
			}
			.mb-sp-only {
				margin-bottom: 20px;
			}
		}
	}
}


/*--------------------
ログイン
---------------------*/
.login-page {
	@media (max-width: map-get($grid-breakpoints, sm)) {
		background-color: #fff;
		align-items: unset;
		flex-direction: unset;
		padding-top: 100px;
	}
}

header.login {
	background-color: #fff;
	padding: 20px 15px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	@media (max-width: map-get($grid-breakpoints, sm)) {
		border-bottom: 1px solid $gray-400;
		padding: 15px;
		.logo {
			max-width: 200px;
		}
	}
}

.login-box {
	.card {
		.login-card-header {
			border-bottom: none;
			padding-top: 20px;
			padding-bottom: 0;
			h1 {
				font-size: 24px;
				font-weight: bold;
				border-bottom: 1px solid $orange;
				display: inline-block;
				padding-bottom: 3px;
			}
		}
		.login-card-body {
			form {
				.form-group {
					label {
						color: $gray-600;
					}
					input {
						background-color: #f4f6fa;
						border-radius: 0;
					}
				}
				.btn-orange {
					width: 100%;
				}
			}
			p {
				font-size: 14px;
			}
			.forgot-password {
				font-size: 14px;
				text-decoration: underline;
			}
			hr {
				margin: 2rem 0;
			}
		}
	}
	@media (min-width: map-get($grid-breakpoints, sm)) {
		width: 400px;
		.card {
			padding: 15px 15px 0;
		}
	}
	@media (max-width: map-get($grid-breakpoints, sm)) {
		width: 100%;
		max-width: 400px;
		.card {
			box-shadow: none;
		}
	}
}
